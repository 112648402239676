import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad (view) {
  return () => import(`@/${view}.vue`)
}

const routes = [
  {
    path: '/response',
    name: 'DCF Response',
    component: lazyLoad('components/SrcResponse')
  },
  {
    path: '/',
    name: 'DPA',
    component: lazyLoad('components/SrcDpa')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
